const typeName = '图像上传组件';

export const IMAGE_UPLOAD_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '图像展示组件',
  required: [
    'outputWord',
    'showPath',
    'isRequired',
    'showName',
    'isShowName',
    'isNumberLimit'
  ],
  properties: {
    outputWord: {
      type: 'string',
      default: '',
      title: '输出路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【输出路径】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    isRequired: {
      type: 'number',
      default: 1,
      title: '是否必填',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否必填】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      default: '',
      title: '组件名称',
      errorMessage: `${typeName}, 组件名称内容不符合要求`
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    },
    isNumberLimit: {
      type: 'number',
      default: 0,
      title: '是否进行数量限制',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否进行数量限制】内容错误，请修正`
      }
    }
  }
};

export default {};
