import { AxiosPromise } from 'axios';
import request from '../../common/request';

const baseUrl = '/manager/project';

export interface ProjectListParamsType {
  projectName: string;
  markGroup?: string;
  status?: string;
  pageNum: number;
  pageSize: number;
}
interface SetTaskIsForbidden {
  taskId: string;
  isFrozen: number;
}
interface Synchronization {
  taskId: string;
}
export function getProjectList(params: ProjectListParamsType): AxiosPromise {
  return request(`${baseUrl}/projectList`, {
    method: 'POST',
    data: params
  });
}

export interface DictionaryListParamsType {
  groupName: string;
}

export function getDictionaryList(params: string): AxiosPromise {
  return request(`${baseUrl}/dictionaryList/${params}`, {
    method: 'GET'
  });
}
export function getFieldMapping(projectId: any): AxiosPromise {
  return request(`/platform/search/getFieldMapping/${projectId}`, {
    method: 'GET'
  });
}
export function launchDataSetOfProject(
  params: launchDataSetOfProjectParams
): AxiosPromise {
  return request(`${baseUrl}/launchDataSetOfProject`, {
    method: 'POST',
    data: params
  });
}

export function trialLaunchDataSetOfProject(
  params: trialLaunchDataSetOfProjectParams
): AxiosPromise {
  return request(`${baseUrl}/trialLaunchDataSetOfProject`, {
    method: 'POST',
    data: params
  });
}

export function getAllotTeamList(
  params: AllotTeamListParamsType
): AxiosPromise {
  return request(`${baseUrl}/taskList`, {
    method: 'POST',
    data: params
  });
}

export interface PublishTaskParamsType {
  projectId: string;
  groupId: number;
  taskIds: string[];
}

export function publishTask(params: PublishTaskParamsType): AxiosPromise {
  return request(`${baseUrl}/publishTask`, {
    method: 'POST',
    data: params,
    disableMessage: true
  });
}

export interface MaterialInfo {
  materialType: number;
  materialUrl: string;
}
export interface launchDataSetOfProjectParams {
  projectId: string;
  dataSetId: number;
}
export interface PreMarkInfo {
  taskNum: number;
  dataNum: number;
}

export interface trialLaunchDataSetOfProjectParams {
  projectId: string;
  dataSetId: number;
  preMarkInfo: PreMarkInfo;
}

export interface TemplateInfo {
  markToolName: string;
  graphs: string[];
  markLabel: any;
  markAttrList: any;
  markStatisticList: any;
}

export interface CheckInfo {
  checkType: number;
  inspectRate: number;
  checkRate: number;
}

export interface TaskSplitInfo {
  taskReplicaCount: number;
  spilitType: number;
  spilitInfo: number;
}

export interface CreateProjectParamsType {
  name: string;
  checkUserIds: any;
  remark: string; // 标注规范文档
  materialInfo: MaterialInfo; // 上传数据相关信息
  templateInfo: TemplateInfo;
  lockTime: number;
  checkInfo: CheckInfo;
  taskSplitInfo: TaskSplitInfo; // 分配任务
  projectId: string;
  isSettleAccount?: number; // 是否结算
}

export interface AllotTeamListParamsType {
  projectId: string;
  pageSize: number;
  pageNum: number;
}

export function createProject(params: CreateProjectParamsType): AxiosPromise {
  return request(`${baseUrl}/createProject`, {
    shouldThrowError: true,
    method: 'POST',
    data: params
  });
}

export interface AppendProjectParamsType {
  checkUserIds: any;
  projectId: string;
  templateInfo: TemplateInfo;
  materialInfo: string;
  isNeed: 0 | 1 | number;
  expectDay: number;
  copyPicture: 0 | 1;
  isAutoInspect: 0 | 1;
  projectName: string;
  standardUrl: string;
  priceId: string;
  taskPriceRemark: string;
  taskSplitInfo: any;
  preMarkInfo: any;
  projectNoticeInfo: {
    projectName: string;
    standardUrl: string;
    priceId: string;
  };
}

export function appendProject(params: any): AxiosPromise {
  return request(`${baseUrl}/appendData`, {
    shouldThrowError: true,
    method: 'POST',
    data: params
  });
}

export interface DeleteProjectParamsType {
  id: string;
}

export function deleteProject(params: DeleteProjectParamsType): AxiosPromise {
  return request(`${baseUrl}/delete?projectId=${params.id}`, {
    method: 'DELETE'
  });
}

export function fetchTemplateInfoList(markType: string): AxiosPromise {
  return request(`${baseUrl}/markTemplateList/${markType}`, {
    method: 'GET'
  });
}

// 获取题库列表接口
export function getQuestionList(params: any): AxiosPromise {
  return request('/manager/question/query', {
    method: 'POST',
    data: params
  });
}
// 题库删除接口
export function deleteQuestionList(taskDataId: number): AxiosPromise {
  return request(`/manager/question/delete?taskDataId=${taskDataId}`, {
    method: 'DELETE'
  });
}

// 录入人联想接口
export function getUserNameList(params: any): AxiosPromise {
  return request('/manager/question/dealMember', {
    method: 'GET',
    params
  });
}

// 获取Cos上传信息
export function getCosInfo(): AxiosPromise {
  return request('/platform/dataSet/getCosInfo', {
    method: 'POST',
    data: {}
  });
}

// 获取图片上传组件Cos上传信息
export function getImageCosInfo(): AxiosPromise {
  return request('/platform/dataSet/getImageCosInfo', {
    method: 'POST',
    data: {}
  });
}

export interface GeneratePresignedUrlData {
  bucketName: string;
  objName: string;
}
// 获取Cos临时token
export function getGeneratePresignedUrl(
  params: GeneratePresignedUrlData
): AxiosPromise {
  return request('/platform/dataSet/generatePresignedUrl', {
    method: 'POST',
    data: params
  });
}

// 数据集列表查询
export function queryDataSetList(params: any): AxiosPromise {
  return request('/platform/dataSet/queryDataSetList', {
    method: 'POST',
    data: params
  });
}

export interface addDataSetType {
  name: string;
  filePathUrl: string;
  fileType: number;
  fileCount: number;
}
// 添加一条数据集
export function addDataSet(params: addDataSetType): AxiosPromise {
  return request('/platform/dataSet/addDataSet', {
    method: 'POST',
    data: params
  });
}

// 添加一个自定义模板的excel数据集
export function addCustomExcelSet(params: addDataSetType): AxiosPromise {
  return request('/platform/dataSet/addCustomExcelDataSet', {
    method: 'POST',
    data: params
  });
}

// 删除一条数据集
export function updateDataSet(params: any): AxiosPromise {
  return request('/platform/dataSet/updateDataSet', {
    method: 'POST',
    data: params
  });
}

// 重启项目
export function revertProject(params: any): AxiosPromise {
  return request('/manager/project/revert', {
    method: 'POST',
    data: params
  });
}
export interface updateDataSetStatusType {
  ids: string[];
  dataSetStatus: number;
}

// 废弃、启用数据集
export function updateDataSetStatus(
  params: updateDataSetStatusType
): AxiosPromise {
  return request('/platform/dataSet/updateDataSetStatus', {
    method: 'POST',
    data: params
  });
}

// 批注记录导出
export function exportData(params: any): AxiosPromise {
  return request('/platform/annotation/exportDataDetail', {
    method: 'POST',
    data: params
  });
}

// 批注记录导出
export function getPriceHistory(params: any): AxiosPromise {
  return request('/manager/price/getPriceHistoryList', {
    method: 'POST',
    data: params
  });
}

// platform/dataSet/addDataSetByExcel excel上传
export function addDataSetByExcel(params: any): AxiosPromise {
  return request('/platform/dataSet/addDataSetByExcel', {
    method: 'POST',
    data: params
  });
}

// /manager/template/getAllCasCadInfo  获取所有标注工具信息
export function getAllCasCadInfo(): AxiosPromise {
  return request('/manager/template/getAllCasCadInfo', {
    method: 'GET'
  });
}

export function synchronization(params: Synchronization): AxiosPromise {
  return request('/manager/task/synchronizeTask', {
    method: 'GET',
    params
  });
}
// /manager/task/setTaskIsForbidden 禁用任务
export function setTaskIsForbidden(params: SetTaskIsForbidden): AxiosPromise {
  return request('/manager/task/setTaskIsForbidden', {
    method: 'POST',
    data: params
  });
}
// /platform/dataSet/batchAddDataSet
export function batchAddDataSet(params: any): AxiosPromise {
  return request('/platform/dataSet/batchAddDataSet', {
    method: 'POST',
    data: params
  });
}
export function appendDataSet(params: any): AxiosPromise {
  return request(`${baseUrl}/appendDataSet`, {
    method: 'POST',
    data: params
  });
}
