const typeName = '标题组件';

export const TITLE_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '标题组件',
  required: ['fontSize', 'showName', 'titleColor'],
  properties: {
    titleColor: {
      type: 'string',
      title: '标题颜色',
      pattern: '^#([a-fA-F0-9]{6})$',
      errorMessage: `${typeName}【标题颜色】内容错误，请修正`
    },
    showName: {
      type: 'string',
      pattern: '.+',
      title: '标题名称',
      errorMessage: `${typeName}, 组件标题名称内容不符合要求`
    },
    fontSize: {
      title: '字号',
      type: 'number',
      default: 14,
      minimum: 14,
      maximum: 20,
      errorMessage: `${typeName}【标题字号】内容错误，请修正`
    }
  }
};

export default {};
