const typeName = '图像展示组件';

export const IMAGE_SHOW_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '图像展示组件',
  required: [
    'jsonPath',
    'showPath',
    'showName',
    'isShowName'
  ],
  properties: {
    jsonPath: {
      type: 'string',
      default: '',
      title: '输入路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【输入路径】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      default: '',
      title: '组件名称',
      errorMessage: `${typeName}, 组件名称内容不符合要求`
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    }
  }
};

export default {};
